import React from 'react';
import Footer from "./components/Footer";
import Header from "./components/Header";
import TeamPicture from "../images/antero_team.jpg";
import {Helmet} from "react-helmet";
import Banner from "./components/Banner";

const Team = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Fashion Antero | Team</title>
            </Helmet>

            <Banner/>

            <Header/>

            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 md:flex">
                <div className="md:hidden mb-6">
                    <img
                        className="w-auto"
                        style={{width: 500}}
                        src={TeamPicture}
                        alt=""

                    />
                </div>
                <div className="mr-6 max-w-2xl">
                    <h2 className="text-xl font-medium text-gray-900">Wie?</h2>
                    <p className="mb-6">Teun & Lesley een duo gepassioneerd door mode en trends!</p>

                    <h2 className="text-xl font-medium text-gray-900">Ons DNA</h2>
                    <p className="mb-6">Betaalbare kleding voor elke modebewuste man! </p>

                    <h2 className="text-xl font-medium text-gray-900">Onze Missie</h2>
                    <p className="mb-6">Elke man het zo aangenaam mogelijk maken door een goede service te bieden en advies te geven voor de juiste outfit.
                        Waarbij het belangrijk is dat de klanten zich thuis voelen, dit aangevuld met de juiste merken is steeds onze ambitie. </p>

                    <h2 className="text-xl font-medium text-gray-900">Wist-je-datje</h2>
                    <p className="mb-6">Antero staat voor ANnick, TEun & ROnny, kortweg omringd door mijn lieftallige ouders!</p>

                </div>


                <div className="hidden md:flex">
                    <img
                        className="w-auto"
                        style={{width: 500}}
                        src={TeamPicture}
                        alt=""

                    />
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Team;
